<template>
	<edit-template style="font-size: 14rem !important;" @confirm="onPreserve" @cancel="onBack" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <el-form class="form-body" :model="data" :rules="rules" ref="form">
      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">客户姓名</span>
          <span style="margin-left: 4rem">{{ data.parent_name }}</span>
        </el-col>
      </el-row>
      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">学生姓名</span>
          <span style="margin-left: 4rem">{{ data.student_name }}</span>
        </el-col>
      </el-row>
      <el-row v-if="Number(data.is_show_phone) === 1" :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">报名手机号</span>
          <span style="margin-left: 4rem">{{ data.phone }}</span>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">中考预估分数</span>
          <el-input v-model="data.student_senior_achievement" placeholder="请输入中考预估分数"></el-input>
<!--          <span style="margin-left: 4rem">{{ data.student_senior_achievement }}</span>-->
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">预估分对应收费档位</span>
          <el-select v-model="data.senior_achievement_fee_level " placeholder="请选择预估分对应收费档位">
            <el-option v-for="item in senior_achievement_fee_levelArr" :key="'senior_achievement_fee_levelArr-'+item" :label="item" :value="item"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row  v-if="Number(data.is_show_phone)  === 1" :span="24" style="margin-top: 24rem;position: relative">
        <el-col>
          <span class="left_item">家长联系方式</span>
          <el-input v-model="data.parent_contact" placeholder="请输入家长联系方式"></el-input>
        </el-col>
<!--        <div style="font-size: 12rem;position: absolute;bottom: -15rem;left: 170rem;color:red;" v-if="data.parent_contact">{{ data.parent_contact.length <= 30 ? '' : '请输入正确的联系方式' }}</div>-->
      </el-row>


      <el-form-item label="是否退费" label-width="162rem" style="margin-top: 24rem" prop="is_refund" v-if="data.is_refund_btn">
        <el-select v-model="data.is_refund" :disabled="isRefundDisabled">
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="银行卡" label-width="162rem" prop="bank_cark" v-show="data.is_refund == 1" :required="!!data.is_refund">
        <el-input v-model="data.bank_cark" placeholder="请输入银行卡号"></el-input>
      </el-form-item>
      <el-form-item label="开户行" label-width="162rem" prop="bank_name" v-show="data.is_refund == 1" :required="!!data.is_refund">
        <el-input v-model="data.bank_name" placeholder="请输入开户行"></el-input>
      </el-form-item>
      <el-form-item label="开户人" label-width="162rem" prop="bank_user" v-show="data.is_refund == 1" :required="!!data.is_refund">
        <el-input v-model="data.bank_user" placeholder="请输入开户人"></el-input>
      </el-form-item>

      <el-form-item label="学生来源" label-width="162rem" prop="source" style="margin-top: 24rem">
        <el-select v-model="data.source" placeholder="请选择学生来源" @change="onSourceChange">
          <el-option v-for="item in sourceArr" :key="'sourceArr-'+item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>

      <el-row :span="24">
        <el-col :span="8">
          <!--          <span class='left_item'>学生来源</span>-->
          <!--          <el-select v-model='data.source' placeholder='请选择学生来源' @change="onSourceChange">-->
          <!--            <el-option v-for='item in sourceArr' :label='item' :value='item'></el-option>-->
          <!--          </el-select>-->
        </el-col>
        <el-col :span="12" v-if="getSourceListArr.includes(this.data.source)">
          <span class="left_item">{{ data.source === '多胞胎' ? '学生姓名' : data.source === '教职工子女' ? '教职工姓名' : '填写来源' }}</span>
          <el-input v-if="getSourceListArr.includes(this.data.source) &amp;&amp; data.source === '其他'" v-model="data.source_ext" placeholder="请输入学生来源" maxlength="50"></el-input>
          <!--          <el-input v-if="getSourceListArr.includes(this.data.source) && data.source === '多胞胎'" v-model="data.source_ext"></el-input>-->
          <el-input v-if="getSourceListArr.includes(this.data.source) &amp;&amp; data.source === '教职工子女'" v-model="data.source_ext" placeholder="请输入教职工姓名" maxlength="50"></el-input>
          <el-select v-model="data.source_ext" :loading="loading" :popper-append-to-body="false" :remote-method="remoteMethodToArr" filterable="" placeholder="搜索学生姓名" remote="" reserve-keyword="" v-if="getSourceListArr.includes(this.data.source) &amp;&amp; data.source === '多胞胎'">
            <el-option v-for="item in optionsTo" :key="'optionsTo-'+item.id" :label="item.name" :value="item.name">
            </el-option>
          </el-select>
        </el-col>

      </el-row>

      <el-row :span="24">
        <el-col>
          <span class="left_item">学生生日历法</span>
          <el-select v-model="data.birthday_type" @change="onBirthdayTypeChange">
            <el-option value="0" label="农历"></el-option>
            <el-option value="1" label="公历"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem" v-if="data.birthday_type === '1'">
        <el-col>
          <span class="left_item">学生实际生日</span>
          <el-date-picker v-model="data.birthday" placeholder="选择日期" type="date" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem" v-else="">
        <el-col>
          <span class="left_item">学生实际生日</span>
          <el-input v-model="data.birthday" placeholder="请输入学生实际生日"></el-input>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">学生的民族</span>
          <el-select v-model="data.student_nation" placeholder="请选择学生民族">
            <el-option v-for="item in nationArr" :key="'nationArr'+item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col :span="24">
          <span class="left_item">学生户籍地址</span>
          <el-select v-model="data.student_register_province" @change="onProvinceChange(1)">
            <el-option v-for="item in provinceArr[0]" :label="item.address_name" :value="item.address_code" :key="'provinceArr-0-'+item.address_code"></el-option>
          </el-select>
          <el-select v-model="data.student_register_city" @change="onCityChange(1)">
            <el-option v-for="item in cityArr[0]" :label="item.address_name" :value="item.address_code" :key="'cityArr-0-'+item.address_code"></el-option>
          </el-select>
          <el-select v-model="data.student_register_area" @change="onDistrict(1)">
            <el-option v-for="item in districtArr[0]" :label="item.address_name" :value="item.address_code" :key="'districtArr-0-'+item.address_code"></el-option>
          </el-select>
<!--          <el-input v-model='data.student_register_address' placeholder='请输入'></el-input>-->
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col :span="24">
          <span class="left_item">学生通信地址</span>
          <el-select v-model="data.student_communication_province" @change="onProvinceChange(2)">
            <el-option v-for="item in provinceArr[1]" :label="item.address_name" :value="item.address_code" :key="'provinceArr-1-'+item.address_code"></el-option>
          </el-select>
          <el-select v-model="data.student_communication_city" @change="onCityChange(2)">
            <el-option v-for="item in cityArr[1]" :label="item.address_name" :value="item.address_code" :key="'cityArr-1-'+item.address_code"></el-option>
          </el-select>
          <el-select v-model="data.student_communication_area" @change="onDistrict(2)">
            <el-option v-for="item in districtArr[1]" :label="item.address_name" :value="item.address_code" :key="'districtArr-1-'+item.address_code"></el-option>
          </el-select>
          <el-input v-model="data.student_communication_address" placeholder="请输入"></el-input>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">学生就读途径</span>
          <el-select v-model="data.student_attend_road" placeholder="请输入学生就读途径">
            <el-option v-for="(item,key) of roadArr" :label="item" :value="key" :key="'roadArr-'+key"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col :span="16">
          <span class="left_item">初中毕业时间</span>
          <el-date-picker v-model="data.student_attend_graduation" placeholder="选择年" value-format="yyyy" type="year">
          </el-date-picker>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col :span="24">
          <span class="left_item">初中毕业班级</span>
          <el-select v-model="data.student_attend_graduation_province" @change="onProvinceChange(3)">
            <el-option v-for="item in provinceArr[2]" :label="item.address_name" :value="item.address_code" :key="'provinceArr-2-'+item.address_code"></el-option>
          </el-select>
          <el-select v-model="data.student_attend_graduation_city" @change="onCityChange(3)">
            <el-option v-for="item in cityArr[2]" :label="item.address_name" :value="item.address_code" :key="'cityArr-2-'+item.address_code"></el-option>
          </el-select>
          <el-select v-model="data.student_attend_graduation_school" :disabled="!(data.student_attend_graduation_province && data.student_attend_graduation_city)">
            <el-option v-for="item in graduationSchoolArr" :value="item.id" :label="item.name" :key="'graduationSchoolArr-'+item.id"></el-option>
          </el-select>
          <el-input v-model="data.student_attend_graduation_class" placeholder="请输入"></el-input>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">初中毕业班主任姓名</span>
          <el-input v-model="data.main_teacher_name" maxlength="50" placeholder="请输入初中毕业班主任姓名"></el-input>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">初中毕业班主任电话</span>
          <el-input v-model="data.main_teacher_phone" maxlength="11" placeholder="请输入初中毕业班主任电话"></el-input>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">高中期望学习语种</span>
          <el-select v-model="data.student_high_languages" placeholder="请选择高中期望学习语种">
            <el-option v-for="(item,key) of foreign_languageArr " :label="item" :value="key" :key="'foreign_languageArr-'+key"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row v-if="phoneShow" :span="24">
        <el-col>
          <span class="left_item"></span>
          <span style="color: red;font-size: 12rem;margin-top: 4rem">请输入正确手机号</span>
        </el-col>
      </el-row>

      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">高中学习方向</span>
          <el-select v-model="data.study_type" placeholder="请选择高中学习方向" @change="onStudyDirectChange">
            <el-option v-for="(item) of direct.study " :label="item.name" :value="item.id" :key="'study-'+item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :span="24" style="margin-top: 24rem">
        <el-col>
          <span class="left_item">高中专业方向</span>
          <el-select v-model="data.major" placeholder="请选择高中专业方向" :disabled="data.study_type == null || data.study_type === ''">
            <el-option v-for="(item) of direct.major " :label="item.name" :value="item.id" :key="'major-'+item.id"></el-option>
          </el-select>
        </el-col>
      </el-row>
    </el-form>



</edit-template>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

export default {
  _config:{
    route:{
      path:"edit",
      meta:{"title":"编辑"},
    }
  },
  name: 'look',
  data() { // 这里存放数据

    let checkInput = (value,callback,message)=>{
      if(this.data.is_refund === 1){
        if(value == null || value === "")callback(new Error(message))
        else callback()
      }else callback()
    }
    let checkBC = (rule,value,callback) =>{
      checkInput(value,callback,"请输入银行卡号")
    }
    let checkBN = (rule,value,callback) =>{
      checkInput(value,callback,"请输入开户行")
    }
    let checkBU = (rule,value,callback) =>{
      checkInput(value,callback,"请输入开户人")
    }
    return {
      data: {
        source_ext: '',
        is_refund: undefined,
        main_teacher_name: '',
        main_teacher_phone: '',
        study_type:null,
        major:null,
        student_attend_graduation_class: '',
        student_attend_graduation_school: '',
        student_attend_graduation_city: '',
        student_attend_graduation_province: '',
        student_attend_graduation: '',
        student_attend_road: '',
        student_communication_address: '',
        student_communication_area: '',
        student_communication_city: '',
        student_communication_province: '',
        student_register_province: '',
        student_register_city: '',
        student_register_area: '',
      },
      provinceArr: [[], [], []], // 省级下拉框数据
      cityArr: [[], [], []], // 市级下拉框数据
      districtArr: [[], [], []], // 区级下拉框数据
      nationArr: [], // 区级下拉框数据
      senior_achievement_fee_levelArr: [], // 预估分数档位
      basicArr: [], // 区级下拉框数据
      direct: {
        study:[],
        major:[],
      }, // 区级下拉框数据
      roadArr: [], // 区级下拉框数据
      middle_classArr: [], // 区级下拉框数据
      sourceArr: [], // 区级下拉框数据
      foreign_languageArr: [], // 区级下拉框数据
      graduationSchoolArr: [], // 区级下拉框数据
      source: [], // 区级下拉框数据
      optionsTo: [], // 区级下拉框数据
      getSourceListArr: ['多胞胎', '教职工子女', '其他'],
      schoolArr: [],
      loading: false,
      isRefundDisabled:false, // 退费选择框禁用状态
      rules:{
        is_refund: {required:true,message:"请选择是否退费",trigger:"change"},
        source: {required: true, message: "请选择学生来源", trigger: "change"},
        bank_cark:{validator:checkBC},
        bank_name:{validator:checkBN},
        bank_user:{validator:checkBU},

      },
      phoneShow: false
    };
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 方法集合
  methods: {
    onStudyDirectChange(e,init) {
      if (!init)this.data.major = null;
      let major = this.direct.study.filter(item=>item.id === e)[0];
      this.direct.major = major?.major||[];
    },
    remoteMethodToArr(query) {
      if (query !== '') {
        this.loading = true;
        this.$_register.get('api/recruit/account-manage/get-student-list', { params: { name: query } }).then(res => {
          this.optionsTo = res.data.data
          this.loading = false;
        })
      } else {
        this.options = [];
      }
    },
    remoteMethodToArrTo(query) {
      if (query !== '') {
        this.loading = true;
        this.$_register.get('api/recruit/account-manage/get-teacher-list', { params: { name: query } }).then(res => {
          this.optionsTo = res.data.data
          this.loading = false;
        })
      } else {
        this.options = [];
      }
    },
    onSourceChange() {
      this.data.source_ext = ''
    },
    onBirthdayTypeChange() {
      if (this.data.birthday_type === '1') this.data.birthday = '2007-01-01'
      else this.data.birthday = ''
    },
    onInput() {
      this.$forceUpdate()
    },
    // 省级选择
    onProvinceChange(val) {
      this.cityArr[val - 1] = [];
      this.districtArr[val - 1] = [];
      this.provinceArr[val - 1].forEach(item => {
        if (val === 1) {
          this.data.student_register_city = '';
          this.data.student_register_area = '';
          this.data.student_register_address = '';
          if (item.address_code === this.data.student_register_province) {
            setTimeout(() => {
              this.cityArr[val - 1] = item.children;
              this.$forceUpdate();
            }, 10);
          }
        }
        if (val === 2) {
          this.data.student_communication_city = '';
          this.data.student_communication_area = '';
          this.data.student_communication_address = '';
          if (item.address_code === this.data.student_communication_province) {
            setTimeout(() => {
              this.cityArr[val - 1] = item.children;
              this.$forceUpdate();
            }, 10);
          }
        }
        if (val === 3) {
          this.data.student_attend_graduation_city = '';
          this.data.student_attend_graduation_class = '';
          this.data.student_attend_graduation_class = '';
          this.data.student_attend_graduation_school = '';
          if (item.address_code === this.data.student_attend_graduation_province) {
            setTimeout(() => {
              this.cityArr[val - 1] = item.children;
              this.$forceUpdate();
            }, 10);
          }
        }
      });
    },

    //  市级选择
    onCityChange(val) {
      this.districtArr[val - 1] = [];
      this.cityArr[val - 1].forEach(item => {
        if (val === 1) {
          this.data.student_register_area = '';
          this.data.student_register_address = '';
          if (item.address_code === this.data.student_register_city) {
            setTimeout(() => {
              this.districtArr[val - 1] = item.children;
              this.$forceUpdate();
            }, 10);
          }
        }
        if (val === 2) {
          this.data.student_communication_area = '';
          this.data.student_communication_address = '';
          if (item.address_code === this.data.student_communication_city) {
            setTimeout(() => {
              this.districtArr[val - 1] = item.children;
              this.$forceUpdate();
            }, 10);
          }
        }
        if (val === 3) {
          this.data.student_attend_graduation_class = '';
          this.data.student_attend_graduation_class = '';
          this.data.student_attend_graduation_school = '';
          if (item.address_code === this.data.student_attend_graduation_city) {
            setTimeout(() => {
              this.districtArr[val - 1] = item.children;
              this.$forceUpdate();
            }, 10);
          }
          this.$_register.get('api/recruit/school/graduation-school', { params: { province: this.data.student_attend_graduation_province, city: this.data.student_attend_graduation_city } }).then(res => {
            this.graduationSchoolArr = res.data.data;
          });
        }
      });
    },

    // 区选择
    onDistrict(val) {
      if (val === 1) this.data.student_register_address = '';
      else this.data.student_communication_address = '';
    },
    // 提交数据
    submit(){

      let data = JSON.parse(JSON.stringify(this.data))
      // if (data.source === "其他") {
      //   data.source = data.sourceTo
      // if (data.source_ext === '') {
      //   this.$message.error('请输入信息来源')
      //   return
      // }
      // }
      this.$_register.post('api/recruit/account-manage/edit-signed-info', data).then(res => {
        if (res.data.status === 0) {
          this.$message.success('编辑成功');
          this.$router.back();
        }
      });
    },
    // 保存
    onPreserve() {
      if (!this.data.source) {
        this.$message.error('请选择学生来源')
      }
      this.$refs.form.validate((status,rule)=>{
        if (status){
          if(this.data.is_refund){
            this.$confirm("是否确认该学生已退费？确认退费后将无法再次更改").then(res=>{
              this.submit()
            })
          }else this.submit()
        }
      })
    },

    onBack() {
      this.$confirm('是否取消编辑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$router.back();
      });
    },
  },
  // 计算属性 类似于 data 概念
  computed: {
  },
  // 监控 data 中的数据变化
  watch: {
    data: {
      handler(val) {
        let regExp = /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/;
        if (val.main_teacher_phone) this.phoneShow = !regExp.test(val.main_teacher_phone);
        else this.phoneShow = false
      },
      deep: true
    }
  },
  //过滤器
  filters: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
    this.$_register.get('api/recruit/common/city').then(res => { // 省级地址获取
      this.provinceArr[0] = res.data.data;
      this.provinceArr[1] = res.data.data;
      this.provinceArr[2] = res.data.data;
      this.provinceArr[3] = res.data.data;
      this.$forceUpdate();
      this.$_register.get('api/recruit/account-manage/get-signed-info?sign_info_id=' + this.$route.query.id+'&order_id='+this.$route.query.order_id).then(res => {
        this.data = res.data.data;
        if(this.data.is_refund)this.isRefundDisabled=true
        this.data.student_high_languages = res.data.data.student_high_languages + '';
        this.data.student_register_province = res.data.data.student_register_province + '';
        this.data.student_register_city = res.data.data.student_register_city + '';
        this.data.student_register_area = res.data.data.student_register_area + '';
        this.data.student_communication_province = res.data.data.student_communication_province + '';
        this.data.student_communication_city = res.data.data.student_communication_city + '';
        this.data.student_communication_area = res.data.data.student_communication_area + '';
        this.data.student_attend_road = res.data.data.student_attend_road + '';
        this.data.student_attend_graduation_class = res.data.data.student_attend_graduation_class + '';
        this.data.student_attend_graduation_province = res.data.data.student_attend_graduation_province + '';
        this.data.student_attend_graduation_city = res.data.data.student_attend_graduation_city + '';
        this.data.student_high_direction = res.data.data.student_high_direction + '';
        this.data.birthday_type = res.data.data.birthday_type + '';
        this.data.study_school_id = parseInt(res.data.data.study_school_id);
        this.onStudyDirectChange(res.data.data.study_type,true)
        this.provinceArr[0].forEach(item => {
          if (item.address_code === this.data.student_register_province) {
            this.cityArr[0] = item.children;
            item.children.forEach(item_ => {
              if (item_.address_code === this.data.student_register_city) {
                this.districtArr[0] = item_.children;
              }
            });
          }
        });
        this.$_register.get('api/recruit/common/get-source-list').then(res => {
          this.sourceArr = res.data.data;
          // this.sourceArr.unshift('其他')
          // this.source = this.sourceArr.filter(item => item === this.data.source)
          // if (!this.source.length) {
          //   this.data.sourceTo = this.data.source
          //   this.data.source = '其他'
          // }
        });
        this.provinceArr[1].forEach(item => {
          if (item.address_code === this.data.student_communication_province) {
            this.cityArr[1] = item.children;
            item.children.forEach(item_ => {
              if (item_.address_code === this.data.student_communication_city) {
                this.districtArr[1] = item_.children;
              }
            });
          }
        });
        this.provinceArr[2].forEach(item => {
          if (item.address_code === this.data.student_attend_graduation_province) {
            this.cityArr[2] = item.children;
          }
        });

        this.$_register.get('api/recruit/school/graduation-school', { params: { province: this.data.student_attend_graduation_province, city: this.data.student_attend_graduation_city } }).then(res => {
          this.graduationSchoolArr = res.data.data;
        });

        this.$_register.get('api/recruit/common/school').then(res => {
          this.schoolArr = res.data.data.entity_school;
        });

        this.$_register.get('api/recruit/common/get-fee-level').then(res => {
          this.senior_achievement_fee_levelArr = res.data.data;
        });

      });
    });


    this.$_register.get('api/recruit/common/nation').then(res => {
    });
    this.$_register.get('api/recruit/common/get-nation-list').then(res => {
      this.nationArr = res.data.data;
    });
    this.$_register.get('api/recruit/common/basic').then(res => {
      this.basicArr = res.data.data.road;
      this.roadArr = res.data.data.road;
      this.middle_classArr = res.data.data.middle_class;
      this.foreign_languageArr = res.data.data.foreign_language;
    });
    // 获取专业方向
    this.$_register.get("api/recruit/common/major").then(res=>{
      this.direct.study = res.data.data;
    })
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
  },
};
</script>
<style lang='scss' scoped>
.el-input, .el-select {
  width: 217rem !important;
  margin: 0 6rem;
}

.left_item {
  display: block;
  width: 150rem;
  min-width: 150rem;
  font-size: 14rem;
  color: #666;
  text-align: end;
  margin-right: 12rem;
}

.el-col {
  display: flex;
  align-items: center;
}

.footer {
  margin-top: 24rem
}

.font {
  font-size: 14rem;
  color: #666;
}
</style>
